import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Queue position: added queue position and estimated time announcement`}</li>
      <li parentName="ul">{`Voice Actions: added new audio type to Audio App`}</li>
      <li parentName="ul">{`Bug Fix: Accounts for a different timezone`}</li>
      <li parentName="ul">{`Bug Fix: Byside ID(Ticket ID) filter on list view panels.`}</li>
      <li parentName="ul">{`Bug Fix: Switching between any view in aggregation panels`}</li>
      <li parentName="ul">{`Bug Fix: Views with default channel don't return data`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      